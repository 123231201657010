.language-suggestion-lightbox {
  position: fixed;
  top: $language-suggestion-lightbox-y-position-small;
  bottom: auto;
  left: 50%;
  z-index: 1000;

  width: 90%;
  max-width: rem-calc(480);
  padding: rem-calc(24);

  background-color: var(--white);
  border: $language-suggestion-lightbox-border;
  border-radius: $language-suggestion-lightbox-border-radius;
  box-shadow: $language-selector-box-shadow;
  transform: translate(-50%);

  animation: sliderightIn 0.3s ease-in-out;

  @include media-breakpoint-up(md) {
    top: $language-suggestion-lightbox-y-position-large;
    right: rem-calc(32);
    left: auto;

    width: auto;

    transform: none;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: rem-calc(16);
    align-items: center;

    color: $language-suggestion-lightbox-color;
  }

  &__content {
    flex: 1;

    font-size: $font-size-body-small;
  }

  &__title {
    margin-bottom: 0;

    font: $font-body-base-bold;

    + .language-suggestion-lightbox__message {
      margin-top: rem-calc(4);
    }
  }

  &__message {
    margin-bottom: rem-calc(4);
  }

  &__actions {
    display: flex;
    flex: 1 0 100%;
    gap: rem-calc(16);
  }

  &__button {
    flex: 1;
    height: rem-calc(32);

    border-radius: $language-suggestion-lightbox-dimensions-border-radius;
  }

  &__icon {
    align-self: flex-start;

    font-size: rem-calc(22);

    text-align: center;

    transform: translateX(rem-calc(8)) translateY(rem-calc(-8));
    cursor: pointer;
  }
}

@keyframes sliderightIn {
  from {
    right: -100vw;
  }

  to {
    right: 0;
  }
}
