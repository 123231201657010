@import 'abstracts/tokens/color-semantics';
@import 'utils/unit';
@import 'abstracts/mixins';
@import 'abstracts/tokens/fonts';
@import 'abstracts/variables';

// Picking only used modules from '~bootstrap/scss/bootstrap';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/utilities/api';

@import 'base/all';

//THEMED NG-CORE COMPONENTS
@import 'ng-core/main';

//WL STYLES
@import 'components/navigation/header-navbar';
@import 'components/actions/common-button';
@import 'components/text-fields/all';
@import 'components/entry-pass/all';
@import 'cookies-advice/main';
@import 'plans-list/main';
@import 'plans-list/plans-list-item/main';
@import 'plans-list/filters/main';
@import 'plans-list/filters/chips';
@import 'plans-list/filters/tree-taxonomies';
@import 'plans-list/filters/sheet';
@import 'plans-list/faqs/main';
@import 'plans-list/links-list/main';
@import 'plans-list/promotion-banner/main';
@import 'highlighted-plans/main';
@import 'navbar/main';
@import 'top-banner/main';
@import 'subheader/main';
@import 'language-modal/main';
@import 'language-selector/main';
@import 'language-suggestion-lightbox/main';
@import 'plan-view/main';
@import 'plan-view/plan-detail/main';
@import 'plan-view/plan-detail/gallery/modal_image_viewer';
@import 'plan-view/plan-detail/plan-general-conditions/main';
@import 'plan-view/plan-detail/plan-description/main';
@import 'plan-view/plan-detail/plan-header/main';
@import 'plan-view/plan-detail/plan-locked-modal/main';
@import 'plan-view/plan-detail/plan-quick-info/main';
@import 'plan-view/plan-detail/plan-rating/main';
@import 'plan-view/plan-detail/plan-reviews/main';
@import 'plan-view/plan-detail/plan-venue/main';
@import 'plan-view/plan-detail/plan-affiliate-code-sheet/main';
@import 'plan-view/plan-detail-tabs/main';
@import 'plan-view/plan-detail/plan-addons-step/main';
@import 'plan-view/help/main';
@import 'plan-view/ticket-selector/main';
@import 'plan-view/addons/main';
@import 'plan-custom-label/main';
@import 'cart/main';
@import 'cart/summary/main';
@import 'cart/voucher/main';
@import 'cart/payment-methods/main';
@import 'cart/payment-methods/sheet';
@import 'cart/terms-and-conditions/main';
@import 'cart/booking-fees/main';
@import 'cart/booking-tax/main';
@import 'cart/basket/main';
@import 'cart/basket/sheet';
@import 'cart/navbar-button/main';
@import 'cart/links/main';
@import 'order-confirmation/item/main';
@import 'order/main';
@import 'order/list/main';
@import 'order/item/main';
@import 'order/qr-codes/main';
@import 'order-detail/main';
@import 'fv-modal/main';
@import 'seating-selector/main';
@import 'calendar/main';
@import 'countdown/main';
@import 'purchase-progress/main';
@import 'purchase-banner/main';
@import 'booking-questions/main';
@import 'selectable/main';
@import 'groups-info/main';
@import 'groups-form/main';
@import 'groups-form-success/main';
@import 'gdpr-modal/main';
@import 'profile-view/main';
@import 'profile/user-info/main';
@import 'profile/menu/main';
@import 'profile/user-preferences';
@import 'banner-not-founded/main';
@import 'page-title/main';
@import 'login/main';
@import 'login/email-form/main';
@import 'modal/main';
@import 'banner-404/main';
@import 'fatal-error/main';
@import 'footer/main';
@import 'footer/nav/main';
@import 'footer/copyright-and-social/main';
@import 'four-hundred-four/main';
@import 'button-login-provider/main';
@import 'email-verification/main';
@import 'toggle/main';
@import 'ticket-review/main';
@import 'static-content/main';
@import 'subscription/main';
@import 'banner/main';
@import 'sidebar/main';
@import 'kakao-talk/main';
@import 'email-update/main';
@import 'reschedule/main';
@import 'phone-number/main';
@import 'promotions/main';
@import 'third-party-terms/main';
@import 'sheet-error/main';
@import 'sheet-confirmation/main';
@import 'bundle/main';
@import 'alert-notification/main';
@import 'status/main';
@import 'ticket/main';
@import 'claim/main';

html {
  scroll-behavior: auto;
}

body {
  position: relative;

  min-width: rem-calc(320);
  min-height: 100vh;

  color: $color-text-main;

  font-weight: normal;
  font-size: rem-calc(16);
  font-family: $font-stack;

  background-color: $body-background-color;

  touch-action: manipulation;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-section {
  min-height: calc(100vh - 150px);
}

a {
  &:hover {
    text-decoration: none;
  }
}

mark {
  @include highlight;
}

::selection {
  color: $selection-text-color;

  background: $selection-color-background;
}

.icon::before {
  display: inline-block;
  -webkit-font-smoothing: antialiased;

  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}

.rotate-180 {
  transform: rotate(180deg);
}

input[type='checkbox'] {
  flex: 0 0 rem-calc(16);
  accent-color: $checkbox-color-background;

  width: rem-calc(16);
  height: rem-calc(16);
  margin-block-start: rem-calc(4);
}
