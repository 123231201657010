.highlighted-plans {
  z-index: $z-index-med;

  width: 100%;
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    @include make-container();
    @include make-container-max-widths();
    padding-block-start: rem-calc(32);
  }

  &__image {
    img {
      width: 100%;
      @include media-breakpoint-up(md) {
        border-radius: rem-calc(8);
      }
    }
  }

  .carousel-indicators {
    display: none;
    margin-bottom: rem-calc(2);
    @include media-breakpoint-up(md) {
      display: flex;

      border-radius: rem-calc(8);
    }
  }
}
